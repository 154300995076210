// 毎月追加されるアバターパーツのうち、どこからが新しく追加されたパーツかを判断する数値(product_number)を管理する定数

// 目
export const newEyeIndex = 206;
// 口
export const newMouthIndex = 185;
// 服
export const newClothIndex = 226;
// 髪
export const newHairIndex = 191;
// 帽子
export const newHatIndex = 202;
